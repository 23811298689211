import styled from "styled-components";
import { StaticIcon } from "../common/icons";
import { useEffect, useState } from "react";
import {
  McList,
  McListItem,
  McPopover,
} from "@maersk-global/mds-react-wrapper";
import {
  getNotifications,
  readNotifications,
} from "../../services/Notifications";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    getAllNotifications();

    const intervalId = setInterval(() => {
      getAllNotifications();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getAllNotifications = () =>
    getNotifications()
      .then((res) => {
        setNotifications(res);
        let filteredNotifications = res.filter((item: any) => !item.isRead);
        setNotificationCount(filteredNotifications.length);
      })
      .catch((err) => {
        console.log(err);
      });

  const readAllNotifications = () => {
    if (notificationCount > 0) {
      readNotifications()
        .then((res) => {
          if (res) setNotificationCount(0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const calculateElapsedTime = (date: string) => {
    const currentDate = new Date();
    const createdAt = new Date(date);
    const elapsedTime = currentDate.getTime() - createdAt.getTime();
    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    switch (true) {
      case seconds < 60:
        return "a few seconds ago";
      case minutes < 60:
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      case hours < 24:
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      case days < 30:
        return `${days} day${days > 1 ? "s" : ""} ago`;
      case months < 12:
        return `${months} month${months > 1 ? "s" : ""} ago`;
      default:
        return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <McPopover
      position="bottom-left"
      arrow={false}
      fit="large"
      maxwidth="350px"
      dontadjustheight
      trigger="click"
      preventcloseonblur
    >
      <IconWithBadge slot="trigger">
        <div
          onClick={() => {
            setNotificationCount(0);
            readAllNotifications();
          }}
          style={{ cursor: "pointer" }}
        >
          <StaticIcon icon="bell" size="24" />
          {notificationCount > 0 && (
            <BadgeContainer>
              <BadgeCount>
                {notificationCount > 10 ? "10+" : notificationCount}
              </BadgeCount>
            </BadgeContainer>
          )}
        </div>
      </IconWithBadge>
      {notifications.length > 0 ? (
        <McList>
          {notifications.map((notification: any, index: number) => (
            <>
              <McListItem
                label={notification.message}
                onClick={() => {
                  console.log("clicked");
                }}
                sublabel={calculateElapsedTime(notification.createdAt)}
              />
              {index !== notifications.length - 1 && <hr />}
            </>
          ))}
        </McList>
      ) : (
        <div style={{ padding: "20px" }}>
          <p>Its all empty here...</p>
        </div>
      )}
    </McPopover>
  );
}

const IconWithBadge = styled.div`
  position: relative;
  align-items: center;
`;

const BadgeContainer = styled.div`
  position: relative;
`;

const BadgeCount = styled.span`
  position: absolute;
  top: -30px;
  left: -5px;
  padding: 2px 6px;
  background-color: #00243d;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
